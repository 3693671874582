import './components/icon/icon';
import { initHLXApp } from './app/init';

initHLXApp();

declare global {
  interface Window {
    hlx: {
      RUM_MASK_URL: string;
      codeBasePath: string;
      lighthouse: boolean;
    };
    dataLayer?: Record<string, unknown>[];
    QSI: {
      API: {
        getIntercept: (selector: string) => Promise<{
          onClose: (handler: () => void) => void;
        }>;
        load: () => {
          then: (handler: (unknown) => void) => unknown;
        };
        run: () => unknown;
        unload: () => unknown;
      };
    };
  }
}
