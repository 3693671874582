import { getLanguage } from 'Utils/getLanguage';
import { isSidekickLibraryActive } from 'Helpers/sidekick/isSidekickLibraryActive';

import { googleTagManagerNoScript } from '../../scripts/googleTagManagerNoScript.js';
import FetchService from '../services/fetch.service';
import HLX from './index';

const READY_STATES = {
  interactive: 'interactive',
  complete: 'complete',
};

async function getPlainHTML(path: string) {
  const newElement = document.createElement('div');
  const htmlString = await FetchService.fetchText(path, {
    cacheOptions: {
      cacheType: 'runtime',
    },
  });
  newElement.innerHTML = htmlString;
  // remove outer div
  newElement.innerHTML = newElement.querySelector('div')!.innerHTML;
  return newElement;
}

async function init() {
  HLX.addBeforeEagerTask(async () => {
    document.body.addEventListener('touchstart', function () {}, true); // eslint-disable-line @typescript-eslint/no-empty-function

    // Qualtrics Website Feedback Snippet Container
    const snippetDiv: string = `<div id='ZN_bJdkTethcxVI8Ie'><!--DO NOT REMOVE-CONTENTS PLACED HERE--></div>`;
    document.body.innerHTML = googleTagManagerNoScript + snippetDiv + document.body.innerHTML;

    const bodyWrapper = document.createElement('div');
    bodyWrapper.classList.add('body-wrapper');

    const content = document.querySelectorAll('header, main, footer');
    [...content].forEach((element) => {
      bodyWrapper.appendChild(element);
    });
    document.body.appendChild(bodyWrapper);

    const main = document.getElementsByTagName('main')[0];
    main.setAttribute('id', 'main');

    if (!isSidekickLibraryActive()) {
      const langPath = getLanguage();
      const header = document.getElementsByTagName('header')[0];
      header.prepend(await getPlainHTML(langPath.concat(`/header.plain.html`)));
      const footer = document.getElementsByTagName('footer')[0];
      footer.append(await getPlainHTML(langPath.concat(`/footer.plain.html`)));
    }

    return Promise.resolve();
  });

  HLX.addLoadDelayedTask(async () => {
    [...document.getElementsByClassName('decorative-line')].forEach((element) => {
      const line = element as HTMLElement;
      line.classList.remove('hidden');
    });

    return Promise.resolve();
  });

  await HLX.init();
}

export function initHLXApp() {
  if (document.readyState === READY_STATES.interactive || document.readyState === READY_STATES.complete) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    init();
  } else {
    document.addEventListener('readystatechange', () => {
      const readyState = document.readyState;
      if (readyState === READY_STATES.interactive || readyState === READY_STATES.complete) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        init();
      }
    });
  }
}
