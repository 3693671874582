import { adjustMarkup } from './adjustMarkup';
import { processSectionMetaData } from './processSectionMetaData';

/**
 * This function is used to transform the sections of the body element.
 * It adjusts the markup of each section by calling the 'adjustMarkup' function.
 * It processes the metadata of each section by calling the 'processSectionMetaData' function.
 * @param {HTMLElement} body - The body element to transform.
 */
export function transformSection(body: HTMLElement) {
  const blockContainer = body.querySelectorAll('header > div, main > div, footer > div');

  blockContainer.forEach((section: HTMLDivElement) => {
    adjustMarkup(section);
    processSectionMetaData(section);
  });
}
